import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import Examplepic from '../../image/icon_zakctake_home.png';
import './header.css';

export default class header extends Component {
  render() {
   
    return (
      <section id="header-sec">

        <div className="row" id="h-row">
        <div className="col" id="h-col-1">
        <div><img src={Examplepic} alt="Flowers"></img></div>
        </div>
        
        <div className="col" id="h-col-2">
        
        <nav id="header-self-navbar">
  <div className="h-nav-div"><b>Login</b></div>
  <div className="h-nav-div"><b>Konto erstellen</b></div>
  <div className="h-nav-div" id="h-b-logout"><b>Abmelden</b></div>
</nav>
      </div>
      </div>

      </section>
      
    )
  }
}





