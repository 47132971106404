import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import './help_menue.css';

export default class help_menue extends Component {
  render() {
    return (
      <section id="help-menue">
        <div className="row">
        <div>
          <p>Was ist Zack / Login</p>
        </div>
        <div>
          <p>Account bearbeiten</p>
        </div>
        <div>
          <p>Inserat Erstellen</p>
        </div>
        <div>
          <p>Formular ausfüllen</p>
        </div>
        
        
        </div>
      </section>
    )
  }
}
