import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import './create_title.css';

export default class Create_title extends Component {
  render() {
    return (
        <section id="create-title">
        <div className="row create-title-row1">
          <div className="col">
            <h1>Neues Formular erstellen</h1>
          </div>
        </div>
        <div className="row create-title-row2" />
        <div className="row create-title-row3" />
      </section>
    )
  }
}
