import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import './create_form.css';
import Examplepic from '../../image/icon_jhfgdbh.png';

export default class create_form extends Component {
  render() {
    return (
      <section id="create-form">
      <form method="post" encType="multipart/form-data">
        <div className="row create-over-row-1">
        <h1>Einstellungen</h1>

        <div className="row create-over-1-1">
        
         
        <div className="col">
        <label htmlFor="vname">Inserat Titel:
          <input type="text" id="fTitle" name="fTitle"></input>
            </label>

            
    
        <label id="create-benutzername-anzeigen">Benutzername:
        <span className="create-anzeige-gruen" id="create-benutzername-anzeigen-span">Anzeigen:</span>
          <input type="checkbox" name="fBenutzername" value="aktiv"></input>
          
        </label>
     
  
  
            <p>Inserat Beschreibung:</p>
<textarea name="fBeschreibung" id="fBeschreibung"></textarea>

         
         </div>
         <div className="col">
         <img src={Examplepic} alt="BeispielPrintscreen" id="beispielPrintscreen"></img>
         <label id="create-benutzername-anzeigen">Anbieternummer:
        <span className="create-anzeige-gruen create-special-aktiv-button-under-picture">Aktiv:</span>
          <input type="checkbox" name="fBenutzername" value="aktiv"></input>
          
        </label>
        </div>

        </div>


        <div className="row create-over-1-2">
          
        <div className="col">
        <label htmlFor="fAnzahl">Anzahl:
    <input type="text" id="fAnzahl" name="fAnzahl"></input>
  </label>
  <label>
          <input type="checkbox" name="fAnzahlCheck" value="Aktiv"></input>
          Aktiv:
        </label>
         </div>
         <div className="col">
         <label htmlFor="fWarteschlange">Warteschlange:
    <input type="text" id="fWarteschlange" name="fWarteschlange"></input>
  </label>
  <label>
          <input type="checkbox" name="fWarteschlangeCheck" value="Aktiv"></input>
          Aktiv:
        </label>
        </div>
        <div className="col">
        <label>Bild einfügen
          <input name="fFormularBild" type="file" size="50" accept="text/*"></input>
          </label> 
        </div>

        </div>


        <div className="row create-over-1-3">
          <div className="create-orange-line"></div>
        <div className="col">
        <label>
    Startzeit festlegen: 
    <input type="date" id="fStartzeit"></input>
</label>
         </div>
         <div className="col">
         <label>
    Countdown Ende: 
    <input type="date" id="fEndzeit"></input>
</label>
        </div>
        <div className="col">
        <label>
    Ende festlegen: 
    <input type="date" id="fFormularEnde"></input>
</label>
        </div>
        </div>


        <div className="row create-over-1-4">
        <div className="col">
        <label>
          <input type="checkbox" name="fZuffalsFunktion" value="Aktiv"></input>
          Zufallsfunktion aktivieren:
        </label>
         </div>
         <div className="col">
        
         <label>
    Zufallsfunktion Timer:
    <input type="time" name="fZufallZeit"></input>
</label>
         </div>
        <div className="col">
        <button type="reset">Zurücksetzen</button>
         </div>

        </div>
        </div>
        
       

        <div className="row create-over-row-2">
        <h1>Elemente</h1>

        <div className="row create-over-2-1">
        
         
        <div className="col">
        <p>Anrede:</p>
        <label>
          <input type="checkbox" name="fAnredeA" value="Aktiv"></input>
          A
        </label>
        <label>
          <input type="checkbox" name="fAnredeP" value="Aktiv"></input>
          !
        </label>

        <p>Geburtsdatum:</p>
        <label>
          <input type="checkbox" name="fGeburtstagA" value="Aktiv"></input>
          A
        </label>
        <label>
          <input type="checkbox" name="fGeburtstagP" value="Aktiv"></input>
          !
        </label>


        <p>Strasse:</p>
        <label>
          <input type="checkbox" name="fStrasseA" value="Aktiv"></input>
          A
        </label>
        <label>
          <input type="checkbox" name="fStrasseP" value="Aktiv"></input>
          !
        </label>
       
   
        
         </div>
         
         <div className="col">
        <p>Vorname:</p>
        <label>
          <input type="checkbox" name="fVornameA" value="Aktiv"></input>
          A
        </label>
        <label>
          <input type="checkbox" name="fVornameP" value="Aktiv"></input>
          !
        </label>

        <p>E-Mail:</p>
        <label>
          <input type="checkbox" name="fEmailA" value="Aktiv"></input>
          A
        </label>
        <label>
          <input type="checkbox" name="fEmailP" value="Aktiv"></input>
          !
        </label>


        <p>Ort:</p>
        <label>
          <input type="checkbox" name="fOrtA" value="Aktiv"></input>
          A
        </label>
        <label>
          <input type="checkbox" name="fOrtP" value="Aktiv"></input>
          !
        </label>
       
   
        
         </div>






         <div className="col">
        <p>Nachname:</p>
        <label>
          <input type="checkbox" name="fNachnameA" value="Aktiv"></input>
          A
        </label>
        <label>
          <input type="checkbox" name="fNachnameP" value="Aktiv"></input>
          !
        </label>


        <p>Telefon:</p>
        <label>
          <input type="checkbox" name="fTelefonA" value="Aktiv"></input>
          A
        </label>
        <label>
          <input type="checkbox" name="fTelefonP" value="Aktiv"></input>
          !
        </label>


        <p>PLZ:</p>
        <label>
          <input type="checkbox" name="fPlzA" value="Aktiv"></input>
          A
        </label>
        <label>
          <input type="checkbox" name="fPlzP" value="Aktiv"></input>
          !
        </label>
       
   
        
         </div>








        </div>


        <div className="row create-over-2-2">
          
        <div className="col">
        <p>Individuelle Checkbox:</p>
        <label>
          <input type="checkbox" name="fICheckboxA" value="Aktiv"></input>
          A
        </label>
        <label>
          <input type="checkbox" name="fICheckboxP" value="Aktiv"></input>
          !
        </label>
        <label htmlFor="fICheckboxValue">
    <input type="text" id="fICheckboxTitel" name="fICheckboxTitel"></input>
  </label>
  <button type="button" id="fMoreCheckbox">+</button>
  <button type="button" id="fLessCheckbox">-</button>
  <label htmlFor="vname">
    <input type="text" id="VariableEinfügenMitCounter" name="VariableEinfügenMitCounter"></input>
  </label>
  <label htmlFor="vname">
    <input type="text" id="VariableEinfügenMitCounter" name="VariableEinfügenMitCounter"></input>
  </label>
         </div>






         <div className="col">
        <p>Individuelles Textfeld:</p>
        <label>
          <input type="checkbox" name="fITextfeldA" value="Aktiv"></input>
          A
        </label>
        <label>
          <input type="checkbox" name="fITextfeldP" value="Aktiv"></input>
          !
        </label>
        <label htmlFor="vname">
    <input type="text" id="fITextfeldTitel" name="fITextfeldTitel"></input>
  </label>




  <p>Individuelle textarea:</p>
        <label>
          <input type="checkbox" name="fITextfeldA" value="Aktiv"></input>
          A
        </label>
        <label>
          <input type="checkbox" name="fITextfeldP" value="Aktiv"></input>
          !
        </label>
        <textarea></textarea>
  
        </div>










         <div className="col">
        <p>Individuelle Optionsfeld:</p>
        <label>
          <input type="checkbox" name="zutat" value="salami"></input>
          A
        </label>
        <label>
          <input type="checkbox" name="zutat" value="salami"></input>
          !
        </label>
        <label htmlFor="vname">
    <input type="text" id="vname" name="vname"></input>
  </label>
  <button type="button" id="berechnen">+</button>
  <button type="button" id="berechnen">-</button>
  <label htmlFor="vname">
    <input type="text" id="vname" name="vname"></input>
  </label>
  <label htmlFor="vname">
    <input type="text" id="vname" name="vname"></input>
  </label>
         </div>









        </div>


        </div>











        </form>




        






      </section>
    )
  }
}
