import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import './h_title.css';


export default class home_title extends Component {
  render() {
    return (
      <section id="h-sec">
         <div className="row">
      <div className="col">
      <div id="h-title-div">
        <h1><b>Schwimmen sie in Anfragen?</b></h1>
        <p><b>Probier es mal mit Zack</b></p>
        <div><b>Jetzt Starten</b></div>
       
        </div>
        
      </div>
      </div>
      </section>
    )
  }
}
