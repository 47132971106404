import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Home from './sites/site_home.js';
import Account from './sites/site_account.js';
import Help from './sites/site_help.js';
import Create from './sites/site_create.js';


ReactDOM.render(<Home />, document.getElementById('zack'));


