import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import Header from '../components/header/header.js';
import Footer from '../components/footer/footer.js';
import Title from '../components/create-title/create_title.js';
import CreateForm from '../components/create-form/create_form';



class Create extends Component {
  render() {
    return (
      <div className="create">

 {/*Menue*/}
        <Header/>
        <Title/>
        <CreateForm/>
{/*Title*/}
     <Footer/>






      </div>
    );
  }
}

export default Create;
