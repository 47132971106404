import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import './h_solutions.css';

export default class home_example extends Component {
  render() {
    return (
      <section id="h-solution">
        <div className="row">
      <div className="col h-col-solutions">
        <div>
          <h2>Überall verfügbar</h2>
          <p>Zack wurde so entwickelt, dass es sich auf jeden Bildschirm anpasst. Dabei spielts keine Rolle ob iOS oder Android Zack funktioniert überall.</p>
        </div>
      </div>
      </div>
      </section>
    )
  }
}
