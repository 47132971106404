import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import './help_title.css';

export default class help_title extends Component {
  render() {
    return (
      <section id="help-title">
  <div className="row help-title-row1">
    <div className="col">
      <h1>Hilfeseite</h1>
      
    </div>
  </div>
  <div className="row help-title-row2" />
  <div className="row help-title-row3" />
</section>
    )
  }
}
























