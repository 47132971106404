import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import Header from '../components/header/header.js';
import Footer from '../components/footer/footer.js';
import Title from '../components/help-title/help_title.js';
import WhatZackLogin from '../components/help-anderes/help_what_zack_login.js';
import Menue from '../components/help-menue/help_menue.js';
import Account from '../components/help-anderes/help_account.js';
import CreateInserat from '../components/help-anderes/help_create_inserat.js';
import FillForm from '../components/help-anderes/help_fill_form';
import './site_help.css';



class Help extends Component {
  render() {
    return (
      <div className="help">

 {/*Menue*/}
        <Header/>
        <Title/>
{/*Title*/}
     <Menue/>

<div className="help-content-over-block"> 




{/*Examples Slider*/}
     <WhatZackLogin/>
{/*Phone, Table, Pc resolution description*/}
     <Account/>
{/*Phone, Table, Pc resolution description*/}
     
     <CreateInserat/>
{/*Phone, Table, Pc resolution description*/}
     
     <FillForm/>
{/*Phone, Table, Pc resolution description*/}
     
</div>



     
{/*Impressum*/}
      <Footer/>






      </div>
    );
  }
}

export default Help;
