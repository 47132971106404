import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import Examplepic from '../../image/icon_zakctake_home.png';
import './a_title.css';

export default class Title extends Component {
  render() {
    return (
      <section id="account-title">
            <div className="row">


            <div className="col">
            
            <h1>Dashboard</h1>
            
            
            <p>Willkommen zurück "Vorname"!</p>
            
            
            
            </div>
            
            
            
            
            
            
            </div>
            <div className="row account-title-row2">
            
            </div>
            <div className="row account-title-row3"></div>
      </section>
    )
  }
}
