import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import Examplepic from '../../image/icon_zakctake_home.png';
import './a_accountinfo.css';

export default class Quicklaunch extends Component {
  render() {
    return (
      <section id="account-Info">
<div className="row a-info-over-row">
<h1 className="a-info-title">Mein Konto</h1>
<div className="row a-info-row1">
<div className="col">
<div><p className="a-info-over-title">Anrede:</p></div>
<div><p className="a-info-value">Herr</p></div>
<div><p className="a-info-over-title">Geburtsdatum:</p></div>
<div><p className="a-info-value">01.01.1970</p></div>
<div><p className="a-info-over-title">Strasse:</p></div>
<div><p className="a-info-value">Musterstrasse 3</p></div>
</div>
<div className="col">
<div><p className="a-info-over-title">Vorname:</p></div>
<div><p className="a-info-value">Max</p></div>
<div><p className="a-info-over-title">E-Mail:</p></div>
<div><p className="a-info-value">Max.Mustermann@gmx.ch</p></div>
<div><p className="a-info-over-title">Ort:</p></div>
<div><p className="a-info-value">Thalwil</p></div>
</div>
<div className="col">
<div><p className="a-info-over-title">Nachname:</p></div>
<div><p className="a-info-value">Mustermann</p></div>
<div><p className="a-info-over-title">Telefon:</p></div>
<div><p className="a-info-value">076 500 50 51</p></div>
<div><p className="a-info-over-title">PLZ:</p></div>
<div><p className="a-info-value">8800</p></div>
</div>
</div>
<div className="col a-info-i-orange-line"></div>
<div className="row a-info-row2">
<div className="col">
<div><p className="a-info-over-title">Benutzername:</p></div>
<div><p className="a-info-value">MusterEinhorn123</p></div>

</div>
<div className="col">
<div><p className="a-info-over-title">Passwort: (Sichtbar)</p></div>
<div><p className="a-info-value">*************</p></div>
</div>
<div className="col">
<div><p className="a-info-over-title">Passwort Wiederholen:</p></div>
<div><p className="a-info-value">*************</p></div>
</div>
</div>
<div className="row a-info-row3">
<div>Bearbeiten</div>
</div>


       </div>
      </section>
    )
  }
}



