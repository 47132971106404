import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import Examplepic from '../../image/icon_zakctake_home.png';
import './a_history.css';

export default class History extends Component {
  render() {
    return (
      <section id="account-history">
       <div className="row a-history-over-row">
       <h1 className="a-history-title">Inserat</h1>
       <div id="a-history-title-a-inserat">Aktive Inserate</div>
       <div id="a-history-title-arch-inserat">Archivierte Inserate</div>
       <div className="row a-history-row">
       <div className="col a-history-i-title">
       <div className="a-history-image-pen"></div><h3>Flohmarktplatz Januar ....</h3><p>20.12.2018</p>
       </div>
       <div className="col a-history-i-link">
       <p>Mit diesem Link gelangst du auf deine Inserat Seite:</p>
       <div>Link</div>
       <div className="a-history-i-c-button-text">Kopieren</div>
       </div>
       
       <div className="col a-history-i-orange-line"></div>
       </div>

       </div>
      </section>
    )
  }
}
