import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import Header from '../components/header/header.js';
import Footer from '../components/footer/footer.js';
import Title from '../components/account-title/acc_title.js';
import Info from '../components/account-info/acc_accountInfo.js';
import Help from '../components/help/acc_help.js';
import History from '../components/account-hystory/acc_history.js';
import Quicklaunch from '../components/account-quicklaunch/acc_quicklaunch.js';



class Account extends Component {
    render() {
      return (
        <div className="account">
  
     {/*Header*/}
     <Header/>
     {/*Title*/}
     <Title/>
     {/*quicklaunch menue bar*/}
     <Quicklaunch/>
     {/*account login date and other*/}
     <Info/>
     {/*history from old created sites*/}
     <History/>
     {/*help link, to the help site*/}
     <Help/>
     {/*Impressum*/}
     <Footer/>
  
  

      </div>
    );
  }
}




export default Account;

