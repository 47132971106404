import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import Examplepic from '../../image/icon_inserat_aufschalten.png';
import Examplepic2 from '../../image/icon_countdown.png';
import Examplepic3 from '../../image/icon_zacktake.png';
import './h_howto.css';

export default class home_howto extends Component {
  render() {
    return (
      <section id="howto-sec">
      <div id="howto">
        <div className="row">
        <h2>So Funktioniert's</h2>
      <div className="col h-howTo-first-col">
        <div><img src={Examplepic} alt="Flowers"></img></div>
        <h3>Inserat aufschalten</h3>
        <p>Erstelle dein Inserat und passe sie individuell an.</p>
      </div>
      <div className="col h-howTo-second-col">
      <div><img src={Examplepic2} alt="Flowers"></img></div>
        <h3>Countdown festlegen</h3>
        <p>Lege einen benutzerdefinierten Countdown fest.</p>
      </div>
      <div className="col h-howTo-third-col">
      <div><img src={Examplepic3} alt="Flowers"></img></div>
        <h3>Ergebnis ansehen</h3>
        <p>Sehe die Resultate bequem im Dashboard an.</p>
      </div>
      </div>
      </div>
      </section>
    )
  }
}
