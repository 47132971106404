import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import './help_what_zack_login.css';

export default class help_what_zack_login extends Component {
  render() {
    return (
      <div id="first-help-whatzack">
        
      </div>
    )
  }
}
