import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import Examplepic from '../../image/icon_zakctake_home.png';
import './footer.css';

export default class footer extends Component {
  render() {
    return (
      <section id="footer">
        <div className="row">
      <div className="col f-col-1">
      <img src={Examplepic} alt="Flowers"></img>
      </div>
      <div className="col f-col-2">
      <nav>
      <div>Impressum</div>
  <div>AGB</div>
  <div>Datenschutz</div>
  </nav>
  <div id="f-greate-by">
      <p>2018 Zacktake made by Inspectron</p>
      </div>
      </div>
      
      </div>
      
      </section>
    )
  }
}
