import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import Header from '../components/header/header.js';
import Footer from '../components/footer/footer.js';
import Title from '../components/home-title/home_title.js';
import Howto from '../components/home-howto/home_howto.js';
import Example from '../components/home-example/home_example.js';
import Solutions from '../components/home-solutions/home_solutions.js';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import Accountsite from './site_account.js';
import Homesite from './site_home.js';
import Createsite from './site_create.js';
import Helpsite from './site_help.js';



class Home extends Component {
  render() {
    return (
      <div className="home">

 {/*Menue*/}
        <Header/>
{/*Title*/}
     <Title/>
{/*How to description*/}
      <Howto/>
{/*Examples Slider*/}
     <Example/>
{/*Phone, Table, Pc resolution description*/}
      <Solutions/>
{/*Impressum*/}
      <Footer/>
      <Router>
      <div>

      
      <Link to="/account/">Account Details</Link>
      <Link to="/create/">Create Form</Link>
      <Link to="/help/">Help Site</Link>
      
      
      <Route path="/account/" exnact component={Accountsite} />
      <Route path="/create/" exact component={Createsite} />
      <Route path="/help/" exact component={Helpsite} />
      </div>
</Router>



      </div>
    );
  }
}

export default Home;
