import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import Examplepic from '../../image/icon_zakctake_home.png';
import './a_help.css';

export default class Help extends Component {
  render() {
    return (
      <section id="account-help">
       <div className="row">

         <div className="col a-help-col1">
        <div>
          <h1>
Hast du Schwierigkeiten?
          </h1>
          <p>
Kein Problem. Schaue dir unsere Hilfe Seite an!
          </p>
        </div>
         </div>

       <div className="col a-help-col2">
      <div>Hilfe Seite Aufrufen</div>
       </div>
       

       </div>
      </section>
    )
  }
}
