import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import Background4 from '../../image/icon_jhfgdbh.png';
import Background3 from '../../image/icon_jh2.png';
import Background2 from '../../image/icon_jh.png';
import Background1 from '../../image/icon_jhfgdbh.png';
import Slider from "react-slick";
import './h_example.css';



export default class home_example extends Component {



  render() {

    const settings = {
      dots: false,
      infinite: true,
      centerMode: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      centerPadding: '0px',
      responsive: [
        {
          breakpoint: 1250,
          settings: {
            centerPadding: '0px',
          }
        },
        {
          breakpoint: 1050,
          settings: {
            centerPadding: '0px',
          }
        },
        {
          breakpoint: 850,
          settings: {
            centerPadding: '0px',
          }
        },
        {
          breakpoint: 700,
          settings: {
            centerPadding: '200px',
            slidesToShow: 1,
            arrows: false,
            infinite: true,
            centerMode: true,
          
          }
        },
        {
          breakpoint: 650,
          settings: {
            centerPadding: '165px',
            arrows: false,
            slidesToShow: 1,
            infinite: true,
            centerMode: true,
          }
        },
        {
          breakpoint: 570,
          settings: {
            centerPadding: '130px',
            arrows: false,
            slidesToShow: 1,
            infinite: true,
            centerMode: true,
          }
        },
        {
          breakpoint: 510,
          settings: {
            centerPadding: '110px',
            arrows: false,
            slidesToShow: 1,
            infinite: true,
            centerMode: true,
          }
        },
        {
          breakpoint: 470,
          settings: {
            centerPadding: '90px',
            arrows: false,
            slidesToShow: 1,
            infinite: true,
            centerMode: true,
          }
        },
        {
          breakpoint: 420,
          settings: {
            centerPadding: '70px',
            arrows: false,
            slidesToShow: 1,
            infinite: true,
            centerMode: true,
          }
        }
        
      ]
    };
      

    return (
     <section id="h-example-sec">
         <div className="row">
      <div className="col h-example-col-1">
        <h2 className="h-format-example">Sehe dir mögliche Beispiele an</h2>
        <p className="h-format-example">Mit Zack lässt sich einfach und bequem ein Inserat eröffnen. Es sind wenig Klicks nötig um Umfrage, Ticketverkäufe, Vermietungen oder Jobausschreibungen zu erstellen.</p>
      </div>
      <div className="example-slider">
      <Slider {...settings}>
        <div  className="h-example-first-div">
        <p className="h-example-font h-example-font-title">
          4 Zimmer für nur 1000Fr pro Monat
        </p>
        <p className="h-example-font h-example-font-time">
          Heute: 00:00:25
        </p>
        <p className="h-example-font h-example-font-beispiel">Beispiel</p>
        
        </div>
        
        <div className="h-example-second-div">
        <p className="h-example-font h-example-font-title">
          4 Zimmer für nur 1000Fr pro Monat
        </p>
        <p className="h-example-font h-example-font-time">
          Heute: 00:00:25
        </p>
        <p className="h-example-font h-example-font-beispiel">Beispiel</p>
        
        </div>
        <div  className="h-example-third-div">
        <p className="h-example-font h-example-font-title">
          4 Zimmer für nur 1000Fr pro Monat
        </p>
        <p className="h-example-font h-example-font-time">
          Heute: 00:00:25
        </p>
        <p className="h-example-font h-example-font-beispiel">Beispiel</p>
        
        </div>
        <div  className="h-example-fourt-div">
        <p className="h-example-font h-example-font-title">
          4 Zimmer für nur 1000Fr pro Monat
        </p>
        <p className="h-example-font h-example-font-time">
          Heute: 00:00:25
        </p>
        <p className="h-example-font h-example-font-beispiel">Beispiel</p>
        
        </div>
        
      </Slider>
      </div>
      
       
      </div>
      <style dangerouslySetInnerHTML={{__html: `
        .h-example-first-div { background-image: url(` + Background1 + `)}
    `}} />
    <style dangerouslySetInnerHTML={{__html: `
        .h-example-second-div { background-image: url(` + Background2 + `)}
    `}} />
    <style dangerouslySetInnerHTML={{__html: `
        .h-example-third-div { background-image: url(` + Background3 + `)}
    `}} />
    <style dangerouslySetInnerHTML={{__html: `
        .h-example-fourt-div { background-image: url(` + Background4 + `)}
    `}} />
      </section>


    );
  }
}
