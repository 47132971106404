import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import LetterIcon from '../../image/icon_inserat_schreiben.png';
import './a_quicklaunch.css';

export default class Info extends Component {
  render() {
    return (
      <section id="account-quickL">
      
       <div className="row">
       <div className="a-row-title">
      <h1>Schnellzugriff</h1>
      </div>
       <div className="col a-quickL-col1">
       
       <div className="a-over-div">
         <h3>Jetzt Starten!</h3>
         <p>Erstelle jetzt dein eigenes <br/>Inserat.</p>
         <img src={LetterIcon}></img>
         <div className="a-under-p">Neues Inserat erstellen</div>
       </div>
       
       </div>
       
       
       
       <div className="col a-quickL-col2">

       <div className="a-overcol2-div">
       <div className="a-div-konto">Mein Konto</div>
       
       <div className="a-div-aInserate">Inserate</div>

       <div className="a-div-help">Hilfe</div>
       </div>

       </div>
       
       
       
       </div>
      </section>
    )
  }
}
